<template>
  <div>
    <a-table
      class="custom-table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="name"
      :scroll="{ x: scrollX, y: scrollY }"
      :total="total"
    >
      <template slot="name" slot-scope="text, record">
        <content-tooltip v-if="text" :title="text">
          <a slot="tooltipBody" @click="showDetail(record.id)">
            {{ text }}
          </a>
        </content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="psuAbnormal" slot-scope="text">
        <span
          :style="{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            background: text ? '#5FC367' : '#FF4D4F'
          }"
        ></span>{{ text ? '正常' : '异常' }}</template>
      <template slot="tempAbnormal" slot-scope="text">
        <span
          :style="{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            background: text ? '#5FC367' : '#FF4D4F'
          }"
        ></span>{{ text ? '正常' : '异常' }}</template>
      <template slot="fanAbnormal" slot-scope="text">
        <span
          :style="{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            background: text ? '#5FC367' : '#FF4D4F'
          }"
        ></span>{{ text ? '正常' : '异常' }}</template>
    </a-table>

    <server-drawer ref="serverDrawer"></server-drawer>
  </div>
</template>

<script>
import ServerDrawer from '@/components/drawer/ServerDrawer'

export default {
  name: 'AbnormalTable',
  components: {
    ServerDrawer
  },
  props: {
    dataSource: {
      type: Array
    },
    scrollY: {
      type: Number,
      default: 400
    }
  },
  data () {
    return {
      total: 0,
      columns: [
        {
          dataIndex: 'name',
          title: '设备名称',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'ip',
          title: 'IP 地址',
          width: 140,
          scopedSlots: {
            customRender: 'ip'
          }
        },
        {
          dataIndex: 'psu_abnormal',
          align: 'center',
          title: '电源状态',
          width: 90,
          scopedSlots: {
            customRender: 'psuAbnormal'
          }
        },
        {
          dataIndex: 'fan_abnormal',
          align: 'center',
          title: '风扇状态',
          width: 90,
          scopedSlots: {
            customRender: 'fanAbnormal'
          }
        },
        {
          dataIndex: 'temp_abnormal',
          align: 'center',
          title: '温度状态',
          width: 90,
          scopedSlots: {
            customRender: 'tempAbnormal'
          }
        }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  methods: {
    showDetail (id) {
      this.$refs.serverDrawer.show(id)
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
