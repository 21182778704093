<template>
  <div>
    <div style="position: absolute; right: 20px; top: 17px">
      <simple-select
        :options="selectOptions"
        @input="v => (pageCount = v.value)"
      ></simple-select>
    </div>
    <a-table
      class="custom-table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :loading="loading"
      row-key="name"
      :scroll="{ x: scrollX, y: scrollY }"
      :total="total"
      @change="change"
    >
      <template slot="name" slot-scope="text, record">
        <content-tooltip v-if="text" :title="text">
          <a slot="tooltipBody" @click="showDetail(record.id)">
            {{ text }}
          </a>
        </content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="monitorAddress" slot-scope="text">
        <a-tooltip :title="text">{{ text }}</a-tooltip>
      </template>
      <template slot="status" slot-scope="text">
        <source-status-tag :status="text"></source-status-tag>
      </template>
    </a-table>

    <switch-drawer ref="switchDrawer"></switch-drawer>
    <security-drawer ref="securityDrawer"></security-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <os-drawer ref="osDrawer"></os-drawer>
  </div>
</template>

<script>
import SourceStatusTag from '@/components/tag/SourceStatusTag.vue'
import SimpleSelect from '@/components/select/SimpleSelect'
import ContentTooltip from '@/components/ContentTooltip'
import SwitchDrawer from '@/components/drawer/SwitchDrawer'
import SecurityDrawer from '@/components/drawer/SecurityDrawer'
import ServerDrawer from '@/components/drawer/ServerDrawer'
import StorageDrawer from '@/components/drawer/StorageDrawer'

export default {
  name: 'HighPerformanceTable',
  components: {
    SourceStatusTag,
    SimpleSelect,
    ContentTooltip,
    SwitchDrawer,
    SecurityDrawer,
    ServerDrawer,
    StorageDrawer,
    'os-drawer': () => import('@/components/drawer/OSDrawer/index.vue')
  },
  props: {
    getFunc: {
      type: Function
    },
    deviceType: {
      type: [String, Array]
    },
    sourceType: {
      type: String
    },
    scrollY: {
      type: Number,
      default: 290
    }
  },
  data () {
    return {
      total: 0,
      dataSource: [],
      loading: false,
      columns: [
        {
          dataIndex: 'status',
          align: 'center',
          title: '状态',
          width: 80,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 140,
          ellipsis: true,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'alert',
          title: '当前告警',
          width: 114,
          scopedSlots: {
            customRender: 'alert'
          }
        },
        {
          dataIndex: 'cpu',
          title: 'CPU 使用率 (%)',
          width: 160,
          scopedSlots: {
            customRender: 'cpu'
          },
          defaultSortOrder: 'descend',
          sorter: true
        },
        {
          dataIndex: 'memory',
          title: '内存使用率 (%)',
          width: 148,
          scopedSlots: {
            customRender: 'memory'
          },
          sorter: true
        },
        {
          dataIndex: 'delay',
          title: '网络延迟 (s)',
          width: 144,
          scopedSlots: {
            customRender: 'delay'
          },
          sorter: true
        },
        {
          dataIndex: 'loss',
          title: '丢包率 (%)',
          width: 120,
          scopedSlots: {
            customRender: 'loss'
          },
          sorter: true
        }
      ],
      fetParams: {
        device_type: this.deviceType,
        sort_by: '',
        order_by: ''
      },
      pageCount: 5,
      selectOptions: [
        { label: '前 5 条', value: 5 },
        { label: '前 10 条', value: 10 },
        { label: '前 20 条', value: 20 },
        { label: '前 30 条', value: 30 },
        { label: '前 50 条', value: 50 }
      ]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {
        limit: this.pageCount
      }
      if (this.fetParams.device_type) {
        params.device_type = this.fetParams.device_type
      }
      if (this.fetParams.sort_by) params.sort_by = this.fetParams.sort_by
      if (this.fetParams.order_by) params.order_by = this.fetParams.order_by
      this.getFunc(params)
        .then(res => {
          this.dataSource = res.data.data
          this.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    change (pagination, filters, sorter) {
      const { order, field } = sorter
      if (order && order !== 'descend') {
        this.fetParams.sort_by = field
        if (order === 'ascend') this.fetParams.order_by = 'asc'
      } else {
        this.fetParams.sort_by = field === 'cpu' ? '' : field
        this.fetParams.order_by = ''
      }
      this.fetch()
    },
    showDetail (id) {
      if (this.sourceType === 'network_device' && this.deviceType === 'switch') this.$refs.switchDrawer.show(id)
      else if (this.sourceType === 'network_device' && Array.isArray(this.deviceType)) this.$refs.securityDrawer.show(id)
      else if (this.sourceType === 'server') this.$refs.serverDrawer.show(id)
      else if (this.sourceType === 'storage') this.$refs.storageDrawer.show(id)
      else if (this.sourceType === 'os') this.$refs.osDrawer.show(id)
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  watch: {
    pageCount () {
      this.fetch()
    }
  }
}
</script>

<style scoped lang="less">
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
