var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{staticClass:"custom-table",attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"row-key":"name","scroll":{ x: _vm.scrollX, y: _vm.scrollY },"total":_vm.total},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return [(text)?_c('content-tooltip',{attrs:{"title":text}},[_c('a',{attrs:{"slot":"tooltipBody"},on:{"click":function($event){return _vm.showDetail(record.id)}},slot:"tooltipBody"},[_vm._v(" "+_vm._s(text)+" ")])]):_c('span',[_vm._v("-")])]}},{key:"psuAbnormal",fn:function(text){return [_c('span',{style:({
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          marginRight: '8px',
          background: text ? '#5FC367' : '#FF4D4F'
        })}),_vm._v(_vm._s(text ? '正常' : '异常'))]}},{key:"tempAbnormal",fn:function(text){return [_c('span',{style:({
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          marginRight: '8px',
          background: text ? '#5FC367' : '#FF4D4F'
        })}),_vm._v(_vm._s(text ? '正常' : '异常'))]}},{key:"fanAbnormal",fn:function(text){return [_c('span',{style:({
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          marginRight: '8px',
          background: text ? '#5FC367' : '#FF4D4F'
        })}),_vm._v(_vm._s(text ? '正常' : '异常'))]}}])}),_c('server-drawer',{ref:"serverDrawer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }